body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background:#212529;
  color: #f7f7f7;
}



.clean-block features {
  padding: 50px 0;
}
.clean-block.slider.dark {
  background-color: #fdfbfb;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px; /* Add margin to display after navbar */
  width: 100%; /* Make the slider take full width */
  max-width: 100%; /* Set the maximum width to 100% for full width */
  height: auto; /* Adjust height automatically */
}

.clean-block.slider.dark img {
  width: 100%; /* Make the image take full width */
  height: auto; /* Adjust height automatically */
}

.text-info {
  margin-top: 20px; /* Adjust the margin as needed */
}
.col-md-5.feature-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0; /* Adjusted margin to increase space between boxes */
  margin-right: 20px; /* Added space between boxes in one row */
  background-color: #343e49; /* Default background color */
}

.col-md-5.feature-box:nth-child(1) {
  background-color: #504848; /* Color for the first box */
}

.col-md-5.feature-box:nth-child(2) {
  background-color: #415841; /* Color for the second box */
}

.col-md-5.feature-box:nth-child(3) {
  background-color: #252544; /* Color for the third box */
}

.col-md-5.feature-box:nth-child(4) {
  background-color: #4e4e2a; /* Color for the fourth box */
}

.col-md-5.feature-box:nth-child(5) {
  background-color: #533853; /* Color for the fifth box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.col-md-5.col-lg-4 {
  background-color: #5e2b2b;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.col-md-5.col-lg-4:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.freelancerlogo {
  width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

/* header image styling */
.img-fluid {
  width: 100%; /* Make the image take full width */
  height: auto; /* Set height to*/
  display: block;
  margin-left: auto;
  margin-right: auto;
}

footer.w3l-footer-29-main {
  background-color: #222020;
  color: #fff;
  padding: 40px 0;
}

footer .footer-29 {
  max-width: 1200px;
  margin: 0 auto;
}

footer .footer-29 h2,
footer .footer-29 h6 {
  color: #fff;
}

footer .footer-29 a {
  color: #fff;
  text-decoration: none;
}

footer .footer-29 a:hover {
  text-decoration: underline;
}

footer .footer-29 .footerw3-btn .btn {
  background-color: #ff6600;
  border: none;
}

footer .footer-29 .footerw3-btn .btn:hover {
  background-color: #e65c00;
}

footer .footer-29 .footer-list-29 {
  margin-bottom: 20px;
}

footer .footer-29 .footer-list-29 h6 {
  margin-bottom: 15px;
}

footer .footer-29 .footer-list-29 ul {
  list-style: none;
  padding: 0;
}

footer .footer-29 .footer-list-29 ul li {
  margin-bottom: 10px;
}

footer .footer-29 .footer-list-29 ul li a {
  color: #ccc;
}

footer .footer-29 .footer-list-29 ul li a:hover {
  color: #fff;
}

footer .footer-29 .subscribe {
  display: flex;
  margin-top: 20px;
}

footer .footer-29 .subscribe input[type="email"] {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
}

footer .footer-29 .subscribe button {
  padding: 10px;
  border: none;
  background-color: #ff6600;
  color: #fff;
  border-radius: 0 5px 5px 0;
}

footer .footer-29 .subscribe button:hover {
  background-color: #e65c00;
}

footer .footer-29 .main-social-footer-29 a {
  color: #fff;
  margin-right: 10px;
  font-size: 18px;
}

footer .footer-29 .main-social-footer-29 a:hover {
  color: #ff6600;
}

footer .footer-29 .bottom-copies {
  margin-top: 30px;
}

footer .footer-29 .bottom-copies p {
  margin: 0;
  color: #ccc;
}

footer #movetop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff6600;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  display: none;
}
.carousel-inner {
  width: 100%;
  height: 400px; /* Adjust as needed */
}

.trendimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  opacity: 0.8; /* Decrease the opacity */
}
/* trending card body styling */

.card-body {
  background-color: #9cd3a0; /* Default background color */
  color: #242121; /* Default text color */
  border-radius: 10px;
  padding: 20px;
}
.custom-button-bg {
  background-color: #96ca6c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  transition: background-color 0.2s;
}